import { format, parseISO } from "date-fns"
import { sv } from "date-fns/locale"

export const formatDateToYearAndMonth = (date: Date | string) => {
  if (typeof date === "string" || date instanceof String) {
    return format(new Date(date), "yyyy-MM")
  }
  return format(date, "yyyy-MM")
}

export const formatDate = (date: string) => {
  if (!date) return null
  return `${format(new Date(parseISO(date)), "yyyy-MM-dd", {
    locale: sv,
  })} ${format(new Date(parseISO(date)), "EEEE", {
    locale: sv,
  })} kl. ${format(new Date(parseISO(date)), "HH:mm", {
    locale: sv,
  })}`
}

export const generateContentObject = object => {
  if (object.nodeType === "Film" || object.type === "Film") {
    return {
      link: object.link,
      date: object.postTypes ? object.postTypes.date : object.date,
      title: object.title,
      iframe: object.iframe ? object.iframe : object.film.film,
      type: object.type ? object.type : object.nodeType,
    }
  }
  if (object.nodeType === "Event" || object.type === "Event") {
    return {
      link: object.link,
      imgSrc: object.featuredImage ? object.featuredImage.node.sourceUrl : null,
      date: object.postTypes ? object.postTypes.date : object.date,
      excerpt: object.excerpt ? object.excerpt : null,
      title: object.title,
      type: object.type,
    }
  }
  if (object.nodeType === "Post" || object.type === "Post") {
    return {
      link: object.link,
      imgSrc: object.featuredImage ? object.featuredImage.node.sourceUrl : null,
      date: object.date,
      excerpt: object.excerpt ? object.excerpt : null,
      title: object.title,
      type: object.nodeType,
    }
  }
  if (object.nodeType === "Photo" || object.type === "Photo") {
    return {
      link: object.link,
      imgSrc: object.featuredImage ? object.featuredImage.node.sourceUrl : null,
      date: object.postTypes ? object.postTypes.date : object.date,
      title: object.title,
      type: object.type,
    }
  }
  if (object.nodeType === "Page" || object.type === "Page") {
    return {
      link: object.link,
      imgSrc: object.featuredImage ? object.featuredImage.node.sourceUrl : null,
      title: object.title,
      type: object.type,
    }
  }
}

export const textTruncate = (str: string, length = 100, ending = "...") => {
  if (str.length > length) {
    return str.substring(0, length - ending.length) + ending
  } else {
    return str
  }
}

export const formatImgSrcUrl = (imgSrc: string) => {
  if (!imgSrc) return null
  if (imgSrc.includes("scaled")) {
    return imgSrc.replace(`-scaled.jpg`, `-600x400.jpg`)
  }
  return imgSrc.replace(`.jpg`, `-600x400.jpg`)
}

export const formatContentImgSrcToHttps = (content: string) => {
  if (content && Boolean(content.length)) {
    return content.replace(
      new RegExp("http://media.admin", "g"),
      "https://media.admin"
    )
  }

  return content
}
